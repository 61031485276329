import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import {
  userSessionActive,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from "src/utils/common";

// Admin Instance
const adminInstance = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_USER_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

const openApiInstance = axios.create({
  baseURL: process.env.REACT_APP_USER_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

const imageUploadInstance = axios.create({
  baseURL: process.env.REACT_APP_IMAGE_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

let isRefreshing = false;
let failedUserQueue: any[] = [];

adminInstance.interceptors.request.use(handleRequest);
adminInstance.interceptors.response.use(handleResponse, (error) =>
  handleError(error, adminInstance, failedUserQueue)
);

openApiInstance.interceptors.request.use(handleUserRequest);
openApiInstance.interceptors.response.use(handleResponse, (error) =>
  handleError(error, openApiInstance, failedUserQueue)
);

imageUploadInstance.interceptors.request.use(handleUserRequest);
imageUploadInstance.interceptors.response.use(handleResponse, (error) =>
  handleError(error, imageUploadInstance, failedUserQueue)
);

function processQueue(error: AxiosError | null, token = null) {
  [failedUserQueue].forEach((failedQueue) => {
    failedQueue.forEach((promise) => {
      if (error) {
        promise.reject(error);
      } else {
        promise.resolve(token);
      }
    });
    failedQueue.length = 0;
  });
}

function applySecurityHeaders(req: AxiosRequestConfig) {
  // Applying security headers
  req.headers["Content-Security-Policy"] = "default-src 'self';";
  req.headers["X-Frame-Options"] = "DENY";
  req.headers["X-Content-Type-Options"] = "nosniff";
  req.headers["X-XSS-Protection"] = "1; mode=block";

  return req;
}

function handleUserRequest(req: AxiosRequestConfig) {
  req = applySecurityHeaders(req);

  return req;
}

function handleRequest(req: AxiosRequestConfig) {
  req = applySecurityHeaders(req);

  if (["v1/auth/login"].includes(req.url || "")) return req;
  req.headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;

  return req;
}
function handleResponse(response: AxiosResponse<any>) {
  if (
    response.config &&
    ["v1/auth/refresh-token", "v1/auth/login"].includes(
      response.config.url || ""
    )
  ) {
    /** * set token if user is verified */ userSessionActive({
      accessToken: response.headers["x-access-token"],
      refreshToken: response.headers["x-refresh-token"],
    });
  }
  return response;
}
async function handleError(
  error: AxiosError<any>,
  instance: AxiosInstance,
  failedQueue: any[]
) {
  const status = error.response ? error.response.status : null;
  const originalRequest = error.config;
  if (
    status === 401 &&
    error.config.url !== "v1/auth/refresh-token" &&
    error.config.url !== "v1/auth/login"
  ) {
    /** * if access-token is expired, get new access-token from refresh-token and retry requests */ if (
      isRefreshing
    ) {
      /** * if refresh token api is pending, adding new request to failed queue */ return new Promise(
        (resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }
      )
        .then((accessToken) => {
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return instance(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    isRefreshing = true;
    return adminInstance
      .post("v1/auth/refresh-token", {
        token: localStorage.getItem(REFRESH_TOKEN),
      })
      .then(async (res) => {
        const accessToken = res?.headers && res.headers["x-access-token"];
        const refreshToken = res?.headers && res.headers["x-refresh-token"];
        userSessionActive({ accessToken, refreshToken });
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
        /** * processing all the failed request with new access token */ return instance(
          originalRequest
        )
          .then((originalResponse) => {
            processQueue(null, accessToken);
            return originalResponse;
          })
          .catch((originalError) => {
            processQueue(originalError, null);
            return Promise.reject(originalError);
          });
      })
      .catch((err: AxiosError) => {
        localStorage.clear();
        window.location.reload();
        return Promise.reject(err);
      })
      .finally(() => {
        isRefreshing = false;
      });
  }
  return Promise.reject(error);
}
export { adminInstance, openApiInstance, imageUploadInstance };
