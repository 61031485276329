import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  tab: {
    textTransform: "none",
    fontSize: "1.14rem",
    fontWeight: 400,
    maxWidth: 500,
    marginRight: "2px",
    minWidth: "fit-content",
  },
  tabSelected: {
    color: "primary.main",
  },
  flexContainer: {
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "custom.greyish.1",
    "&.MuiTabs-root": {
      "&.MuiTabs-scroller, .MuiTabs-fixed": {
        overflowX: "auto !important",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
  },
  indicator: {
    height: 4,
  },
});

export default styles;
