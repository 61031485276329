import Box from "@mui/material/Box";
import { createSearchParams, useNavigate } from "react-router-dom";
import Chip from "src/components/common/Chip";
import { Button } from "src/components/common/FormComponents";
import {
  CHIP_STATUS,
  PROJECT_STATUS,
  statusMessages2,
} from "src/constants/common";
import { path } from "src/constants/path";
import defaultStyles from "../styles";

export const RenderChip = (params) => {
  return (
    <Chip
      label={PROJECT_STATUS[params.row.chipTitle]}
      status={CHIP_STATUS[params.row.status]}
      description={params.row.remarks}
      showTooltip={PROJECT_STATUS[params.row.chipTitle] === "Rejected"}
    />
  );
};

export const RenderButton = (params) => {
  const occupiedArea = params?.row?.data?.landSpecification?.occupiedArea;

  return (
    <Box
      component="a"
      href={`${path.projectDetailView}?${createSearchParams({
        projectId: params.id,
        projectType: params.row.data.projectType,
        projectStatus: params.row.data.status,
        isOccupied: occupiedArea,
      })}`}
      sx={{
        ...defaultStyles.renderButtonText,
        textDecoration: "none",
        width: "100%",
        "& .MuiChip-root ": {
          width: "80%",
        },
      }}
    >
      <Chip
        label={`${params?.row?.registrationNo}`}
        status={
          Number(occupiedArea) === 0 && params.row.data.status === "APPROVED"
            ? "draft"
            : "success"
        }
      />
    </Box>
  );
};

export const RenderDemandDraft = (params) => {
  return (
    <>
      {params?.row?.status === "VERIFY_DEMAND_NOTE" ||
      params?.row?.status === "UNDER_QUERY" ? (
        <Box
          component="a"
          href={`${path.demandDraft}?${createSearchParams({
            id: params.id,
          })}`}
          sx={{ ...defaultStyles.renderButtonText }}
        >{`${
          params?.row?.status === "VERIFY_DEMAND_NOTE"
            ? "Verify Cost Estimate"
            : "Under Query"
        }`}</Box>
      ) : (
        <Box sx={{ color: "red !important" }}>
          {statusMessages2[params?.row?.status] ?? params?.row?.status}
        </Box>
      )}
    </>
  );
};

export const RenderViewDemandDraft = (params) => {
  const navigate = useNavigate();
  return (
    <>
      {params?.row?.status === "VERIFICATION_PENDING" ? (
        <Box
          component="a"
          href={`${path.viewDemand}?${createSearchParams({
            id: params.id,
          })}`}
          sx={{ ...defaultStyles.renderButtonText }}
        >{`${"Verify Payment"}`}</Box>
      ) : (
        <Box sx={{ color: "red !important" }}>-----</Box>
      )}
    </>
  );
};

export const RenderView = (params) => {
  return (
    <Box
      component="a"
      href={`${path.landOwnershipDetails}?${createSearchParams({
        id: params.id,
      })}`}
      sx={{ ...defaultStyles.renderButtonText }}
    >{`${params?.row?.id}`}</Box>
  );
};
