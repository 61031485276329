import { Box, CircularProgress } from "@mui/material";
import { usePDF } from "@react-pdf/renderer";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import Card from "src/components/common/Card";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import MapComponent from "src/components/common/KML";
import ViewForm from "src/components/common/ViewForm";
import ViewImages from "src/components/common/ViewImage";
import { CustomStyles, getStyles } from "src/styles/theme";
import {
  getLandDetailsById,
  getProjectById,
  putDeselectDetails,
} from "src/utils/api/dashboard";
import defaultStyles, { StylesClasses } from "../styles";
import {
  dateAndTimeStamp,
  downloadSheet,
  formatToIndianCurrency,
} from "src/utils/helper";
import { Button } from "src/components/common/FormComponents/Buttons";
import { useAppSelector } from "src/redux/store";
import { useReactToPrint } from "react-to-print";
import { FileUpload, Input } from "src/components/common/FormComponents";
import { useForm } from "react-hook-form";
import { SuccessModal } from "src/components/common/modal";
type WaterProjectProps = {
  projectId: string;
  customStyles?: CustomStyles<StylesClasses>;
};

const LandOwnershipDetails = ({}: WaterProjectProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles);
  const [projectData, setProjectData] = useState<any>([]);
  const [pdfLink, setPdfLink] = useState<string>();

  const componentRef = useRef();

  const [searchParams] = useSearchParams();

  const userId = searchParams.get("id");

  const {
    data: userData,
    isLoading,
    refetch,
  } = useQuery(["userDetails", userId], () => getLandDetailsById(userId), {
    onSuccess: (res: AxiosResponse) => {
      setProjectData(res?.data);
    },
    onError: (req: AxiosError) => {},
    enabled: !!userId,
    refetchOnMount: true,
  });

  const projectId = projectData?.projectDetailId;

  const { data: projectDetails, isLoading: isProjectDetailLoading } = useQuery(
    ["projects", projectId],
    () => getProjectById(projectId),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log(res);
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  const [instance] = usePDF({});

  useEffect(() => {
    const blob = instance.blob;

    if (!!blob) {
      const dataURL = URL.createObjectURL(blob);
      setPdfLink(dataURL);
    }
  }, [instance]);

  const exportQuery = (data) => {
    const extractedData = data.map((item) => ({
      Name: item.user.firstName,
      Query: item.query,
      created_at: dateAndTimeStamp(item.created_at, "IST"),
    }));
    downloadSheet(extractedData);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@media print { body { -webkit-print-color-adjust: exact; } }",
  });

  const totalArea = Number(projectData?.totalArea);

  const [deselectState, setDeselectState] = useState(false);
  const {
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      remark: "",
      file: undefined,
    },
  });

  const { mutate } = useMutation(
    "handleDeselect",
    ({ data }: any) => putDeselectDetails(userId, data),
    {
      onSuccess: () => {
        setDeselectState(true);
        refetch();
      },
    }
  );
  const handleDeselect = () => {
    const fileIds = getValues("file").map((item) => item.key);
    mutate({
      data: {
        remark: getValues("remark"),
        fileIds,
      },
    });
  };

  return (
    <Box>
      {!isLoading ? (
        <DashboardWrapper
          title="Eco-Restoration Block Details"
          customStyles={{
            container: { position: "relative" },
            children: { paddingBottom: "80px" },
          }}
          buttonText="Print"
          onButtonClick={handlePrint}
          // backPath={path.home}
          showBackBtn
        >
          <Box ref={componentRef} id="printable-content">
            <Box {...styles("projectName")}>
              Activity Registration No: {userData?.data?.id}
            </Box>

            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Entity Details</Box>

              <ViewForm
                label="Entity Name"
                value={projectData?.user?.firstName}
              />
              <ViewForm label="Email" value={projectData?.user?.email} />
              <ViewForm
                label="Phone Number"
                value={`${projectData?.user?.phoneNumber}`}
              />
            </Box>
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Eco-Restoartion Block Details</Box>
              <ViewForm
                label="Land Registration No"
                value={projectData?.projectDetail?.registrationNo}
              />
              <ViewForm
                label="Land Type"
                value={projectDetails?.data?.landType}
              />
              <ViewForm
                label="Total Land Area(Hectares)"
                value={projectDetails?.data?.landSpecification?.totalArea}
              />
              <ViewForm
                label="Land Area Requested "
                value={totalArea.toFixed(2)}
              />
              <ViewForm
                label="State"
                value={projectDetails?.data?.address?.stateName}
              />
              <ViewForm
                label="District"
                value={projectDetails?.data?.address?.districtName}
              />
              <ViewForm
                label="Division"
                value={projectDetails?.data?.landSpecification?.division}
              />
              <ViewForm
                label="Range"
                value={projectDetails?.data?.landSpecification?.range}
              />
              <ViewForm
                label="Beat"
                value={projectDetails?.data?.landSpecification?.beat}
              />
              <ViewForm
                label="TreeCanopy Density"
                value={(+projectDetails?.data?.landSpecification
                  ?.treeCanopyDensity).toFixed(2)}
              />
              <ViewForm
                label="Edaphic Details"
                value={projectDetails?.data?.landSpecification?.edaphicDetails}
              />
              <ViewForm
                label="Forest Type"
                value={projectDetails?.data?.landSpecification?.forestType}
              />
              <ViewForm
                label="Geological Features"
                value={
                  projectDetails?.data?.landSpecification?.geologicalFeatures
                }
              />
              <ViewForm
                label="Hydrological Conditions"
                value={
                  projectDetails?.data?.landSpecification
                    ?.hydrologicalConditions
                }
              />
              <ViewForm
                label="Slope And Terrain"
                value={projectDetails?.data?.landSpecification?.slopeAndTerrain}
              />
              <ViewForm
                label="Additional Information"
                value={projectDetails?.data?.landSpecification?.description}
              />
            </Box>
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>GeoTagged Images</Box>
              <Box {...styles("imageWrapper")}>
                {projectDetails?.data?.images.length && (
                  <>
                    {projectDetails?.data?.images?.map((item) => (
                      <Card
                        key={item?.imageUrl?.fileUrl}
                        title={`GeoTagged Image`}
                        image={item?.imageUrl?.fileUrl}
                      />
                    ))}
                  </>
                )}
              </Box>
            </Box>
            {projectDetails?.data?.projectQuery && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("infoContainer")}>
                  <Button
                    onClick={() =>
                      exportQuery(projectDetails?.data?.projectQuery)
                    }
                    text={""}
                    variant="outlined"
                    src="/assets/svg/export.svg"
                    customStyles={{
                      root: {
                        minWidth: "40px",
                      },
                    }}
                  />
                  <Box {...styles("heading")}>Project Query</Box>
                </Box>
                {projectDetails?.data?.projectQuery.map((item) => (
                  <Box sx={{ paddingTop: "20px" }}>
                    <ViewForm
                      label={item.user.firstName}
                      value={item.query}
                      date={dateAndTimeStamp(item.created_at, "IST")}
                    />
                  </Box>
                ))}
              </Box>
            )}
            {projectData?.demandNote && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>Demand Note Details</Box>
                <ViewForm
                  label="Eco-Restoration Cost"
                  value={`${formatToIndianCurrency(projectData?.demandNote)}`}
                />
                <ViewForm
                  label="Admin Charges"
                  value={`${
                    formatToIndianCurrency(projectData?.icfreCharges) ?? "-"
                  }`}
                />
                <ViewForm
                  label="Demand Note Amount"
                  value={`${formatToIndianCurrency(
                    +`${Number(projectData?.demandNote)}` +
                      +`${Number(projectData?.icfreCharges)}`
                  )}`}
                />
                <ViewForm
                  label="Net Planned Land Area(Hectares)"
                  value={projectData?.netPlantationArea}
                />
                <ViewForm
                  label="Tentative Month to Start Plantation"
                  value={`${projectData?.tentativeDateForPlantation}`}
                />
                <Box {...styles("formWrapper")}>
                  <Box {...styles("heading")}>
                    Ms Excel copy of the cost estimate
                  </Box>
                  <Box {...styles("documentGroup")}>
                    <ViewImages
                      fileUrl={projectData?.paymentBreakdownImage}
                      label={""}
                    />
                  </Box>
                </Box>
                <Box {...styles("formWrapper")}>
                  <Box {...styles("heading")}>
                    PDF of technically sanctioned/approved cost estimate
                  </Box>
                  <Box {...styles("documentGroup")}>
                    <ViewImages
                      fileUrl={projectData?.paymentBreakdownPdfImage}
                      label={""}
                    />
                  </Box>
                </Box>

                <Box {...styles("formWrapper")}>
                  <Box {...styles("heading")}>KML File</Box>
                  <Box {...styles("documentGroup")}>
                    <ViewImages
                      fileUrl={projectData?.grantedLandImage}
                      label={""}
                    />
                  </Box>
                </Box>
                <Box {...styles("documentGroup")}>
                  {!isProjectDetailLoading && !isLoading && (
                    <MapComponent kmlUrl={projectData?.grantedLandImage} />
                  )}
                </Box>
                <Box {...styles("imageWrapper")}>
                  {projectData?.geoTaggedImages.map((value) => {
                    return (
                      <Card
                        key={value}
                        title={`GeoTagged Image`}
                        image={value}
                      />
                    );
                  })}
                </Box>
                <Box {...styles("formWrapper")} className="no-print">
                  <Box {...styles("heading")}>Demand Note PDF</Box>
                  <Box {...styles("documentGroup")}>
                    <ViewImages
                      fileUrl={projectData?.uploadedDemandNote}
                      label={""}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {projectData?.landOwnerShipQuery.length > 0 && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("infoContainer")}>
                  <Button
                    onClick={() => exportQuery(projectData?.landOwnerShipQuery)}
                    text={""}
                    variant="outlined"
                    src="/assets/svg/export.svg"
                    customStyles={{
                      root: {
                        minWidth: "40px",
                      },
                    }}
                  />
                  <Box {...styles("heading")}>Demand Note Query</Box>
                </Box>
                {projectData?.landOwnerShipQuery.map((item) => (
                  <Box sx={{ paddingTop: "20px" }}>
                    <ViewForm
                      label={item.user.firstName}
                      value={item.query}
                      date={dateAndTimeStamp(item.created_at, "IST")}
                    />
                  </Box>
                ))}
              </Box>
            )}
            {projectData?.remark && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>Admin Remark with Demand Note</Box>
                <ViewForm label="Remarks" value={projectData?.remark} />
              </Box>
            )}
            {projectData?.bankDetail && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>Transaction Details</Box>
                <ViewForm
                  label="Eco-Restoration Cost"
                  value={formatToIndianCurrency(
                    projectData?.ecoRestorationCost
                  )}
                />
                <ViewForm
                  label="TDS On Eco-Restoration Cost"
                  value={formatToIndianCurrency(
                    projectData?.ecoRestorationCostTDS
                  )}
                />
                <ViewForm
                  label="Admin Charges"
                  value={formatToIndianCurrency(projectData?.adminCharges)}
                />
                <ViewForm
                  label="TDS On Admin Changes"
                  value={formatToIndianCurrency(projectData?.adminChargesTDS)}
                />
                <ViewForm
                  label="Total TDS Amount "
                  value={formatToIndianCurrency(
                    +projectData?.ecoRestorationCostTDS +
                      +projectData?.adminChargesTDS
                  )}
                />
                <ViewForm
                  label="Amount Paid By Entity"
                  value={formatToIndianCurrency(projectData?.amountPaid)}
                />
                <ViewForm
                  label="Account Number"
                  value={projectData?.bankDetail?.accountNumber}
                />
                <ViewForm
                  label="Bank Name"
                  value={projectData?.bankDetail?.bankName}
                />
                <ViewForm
                  label="Branch Name"
                  value={projectData?.bankDetail?.branchLocation}
                />
                <ViewForm
                  label="IFSC Code"
                  value={projectData?.bankDetail?.ifscCode}
                />
                <ViewForm
                  label="Transaction Date"
                  value={projectData?.transactionDate}
                />
                <ViewForm
                  label="Transaction Type"
                  value={projectData?.transactionType}
                />
                <ViewForm
                  label="Transaction Number"
                  value={projectData?.transactionNumber}
                />
                <Box {...styles("formWrapper")}>
                  <Box {...styles("heading")}>Transaction Receipt </Box>
                  <Box {...styles("documentGroup")}>
                    <ViewImages
                      fileUrl={projectData?.paymentImageUrl}
                      label={""}
                    />
                  </Box>
                </Box>
                <ViewForm
                  label="Entity Remark Regarding Payment"
                  value={projectData?.additionalInfo}
                />
              </Box>
            )}
            {projectData?.paymentAcknowledgmentReceipt && (
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>Payment Verification Details</Box>
                <Box {...styles("formWrapper")}>
                  <Box {...styles("heading")}>Payment Receipt</Box>
                  <Box {...styles("documentGroup")}>
                    <ViewImages
                      fileUrl={projectData?.paymentAcknowledgmentReceipt}
                      label={""}
                    />
                  </Box>
                </Box>
                <ViewForm
                  label="Admin Remark on Payment"
                  value={projectData?.paymentRemark}
                />
              </Box>
            )}
          </Box>
          {/* Deselect remark and image */}
          {projectData?.comments[0]?.message && (
            <Box {...styles("formWrapper")}>
              <Box {...styles("heading")}>Deselect Details</Box>
              <ViewForm
                label="Remarks"
                value={projectData?.comments[0]?.message}
              />
              {!!projectData?.deselectedFiles?.length && (
                <Box
                  {...styles("documentGroup", {
                    mt: "20px",
                    flexDirection: "column",
                  })}
                >
                  {projectData?.deselectedFiles.map((item) => (
                    <ViewImages imageId={item.fileId} />
                  ))}
                </Box>
              )}
            </Box>
          )}
          {(projectData?.status === "GENERATE_DEMAND_NOTE" ||
            projectData?.status === "REGENERATE_DEMAND_NOTE" ||
            projectData?.status === "PAYMENT_PENDING") && (
            <Box>
              <Box sx={{ marginTop: "30px" }}>
                <Input
                  name="remark"
                  label="Deselect Remark*"
                  control={control}
                  errors={errors}
                  multiline
                  height="360px"
                  customStyles={{
                    input: {
                      height: "auto",
                      fontFamily: "Poppins",
                      width: { sm: "100%", lg: "60%" },
                    },
                  }}
                  rules={{
                    validate: {
                      isLength: (value) => {
                        return value && value["length"] >= 1;
                      },
                    },
                  }}
                />
                <FileUpload
                  name="file"
                  label="Upload Documents"
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                  clearErrors={clearErrors}
                  maxLimit={5}
                  trigger={trigger}
                  customStyles={{
                    wrapper: {
                      width: "60%",
                    },
                  }}
                  rules={{
                    validate: {
                      isLength: (value) => {
                        return value && value["length"] >= 1;
                      },
                    },
                  }}
                />
                <Button
                  type="submit"
                  text="Deselect"
                  onClick={() => handleDeselect()}
                  disabled={!isValid}
                />
              </Box>
            </Box>
          )}

          <SuccessModal
            open={deselectState}
            heading="Land Deselected Successfully"
            handleModalClose={() => setDeselectState(false)}
          />
        </DashboardWrapper>
      ) : (
        <Box {...styles("loading")}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default LandOwnershipDetails;
