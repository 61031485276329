import dataProvider from "src/dataProvider";
import { Project } from "src/types/api";

let fetcher = dataProvider("adminInstance");

const endPoints = {
  allProjects: "/projects?projectType=LAND_LEASED",
  myProfile: "/user",
  allUser: "/user",
  getProjectById: "/projects",
  getImageById: "/upload/file",
  updateProjectStatus: "/projects",
  allRegistrationDetails: "/projects/land-ownership",
};

export const getAllProjects = async (queryParams: any) => {
  return fetcher.get<{ count: number; rows: Project[] }>(
    `${endPoints.allProjects}`,
    queryParams
  );
};

export const getMyProfileData = async (id?: string) => {
  return fetcher.get(`${endPoints.myProfile}/${id}`);
};

export const getProjectById = async (id: string) => {
  return fetcher.get<Project>(`${endPoints.getProjectById}/${id}`);
};

export const getImageById = async (id: string) => {
  return fetcher.get(`${endPoints.getImageById}/${id}`);
};

export const updateProjectStatus = async (id: string, data: any) => {
  return fetcher.patch(`${endPoints.updateProjectStatus}/${id}`, data);
};

export const getAllUser = async (queryParams: any) => {
  return fetcher.get(`${endPoints.allUser}`, queryParams);
};

export const getAllRegistration = async (queryParams: any) => {
  return fetcher.get<any>(`${endPoints.allRegistrationDetails}`, queryParams);
};

export const demandDraft = (data: {
  id: string;
  data: {
    demandNote: number;
  };
}) => {
  return fetcher.patch<any>(
    `/projects/land-ownership/${data.id}/demand-notes`,
    data.data
  );
};

export const viewDemandDraft = (data: {
  id: string;
  data: {
    status: string;
    remark: string;
  };
}) => {
  return fetcher.patch<any>(
    `/projects/land-ownership/${data.id}/status`,
    data.data
  );
};

export const getLandDetailsById = async (id: string) => {
  return fetcher.get<any>(`/projects/land-ownership/${id}`);
};

export const userStatus = (data) => {
  return fetcher.patch<any>(`/user/status`, data);
};

export const verifyDemandDraft = (data: {
  id: string;
  data: {
    isVerified: boolean;
  };
}) => {
  return fetcher.patch<any>(
    `/projects/land-ownership/${data.id}/verify-demand-notes`,
    data.data
  );
};

export const verifyFundApplication = (data: {
  FundApplicationId: string;
  data: {
    status: string;
    remark: string;
    totalAmountReleased?: number;
    paymentApproveDocId?: string;
  };
}) => {
  return fetcher.patch<any>(
    `/projects/land-ownership/fund-request/${data.FundApplicationId}/status`,
    data.data
  );
};

export const deleteUser = async (id: string) => {
  return fetcher.delete(`/user/${id}`);
};

export const queryHistory = (data) => {
  return fetcher.patch<any>(`/projects/remark/history`, data);
};

export const fundApplicationQuery = (data) => {
  return fetcher.post<any>(`/projects/land-ownership/fund-request/query`, data);
};

export const getDashboardDetails = async (queryParams: any) => {
  return fetcher.get<any>(`/projects/dashboard`, queryParams);
};

export const getLandDashboardDetails = async (queryParams: any) => {
  return fetcher.get<any>(`/projects/land-ownership/dashboard`, queryParams);
};

export const getUserDashboardDetails = async () => {
  return fetcher.get<any>(`/user/dashboard`);
};

export const getTableDashboardDetails = async (queryParams: any) => {
  return fetcher.get<any>(`/projects/dashboard/table`, queryParams);
};

export const getIOdDetails = async () => {
  return fetcher.get<any>(`/user/implementing-officers`);
};

export const addProjectToIo = async (
  projectId: string,
  implementingOfficerId: string
) => {
  return fetcher.patch(`/projects/${projectId}/implementing-officer`, {
    implementingOfficerId: implementingOfficerId,
  });
};

export const changePassword = async (data: any) => {
  return fetcher.post("/auth/change-password", data);
};

export const getEntityDashboardDetails = async (queryParams: any) => {
  return fetcher.get<any>(`/projects/dashboard/entity-table`, queryParams);
};

export const getFundRequest = async (queryParams: any) => {
  return fetcher.get(`/projects/land-ownership/fund-request`, queryParams);
};

export const getFundApplicationById = async (id: string) => {
  return fetcher.get<any>(`/projects/land-ownership/fund-request/${id}`);
};

export const getCostEstimateDetails = async (queryParams: any) => {
  return fetcher.get(`/monitoring/target`, queryParams);
};

export const getProgressDetails = async (queryParams: any) => {
  return fetcher.get(`/monitoring/target/land-ownerships`, queryParams);
};

export const getImages = async (id: any) => {
  return fetcher.get<any>(`/monitoring/progress/images`, {
    progressId: id,
  });
};

export const getProgressDetailsHistory = async (id: string) => {
  return fetcher.get<any>(`/monitoring/progress/history`, {
    targetId: id,
  });
};

export const getProgressReportDetails = async (queryParams: any) => {
  return fetcher.get<any>(`/monitoring/progress`, queryParams);
};

export const getMointoringMember = (id) => {
  return fetcher.get<any>(`/monitoring/committee?landOwnershipEpid=${id}`);
};

export const getProgressYear = () => {
  return fetcher.get<any>(`/monitoring/target/year`);
};

export const getAllMonitoringDetailsData = async (queryParams: any) => {
  return fetcher.get<any>(`/monitoring`, queryParams);
};

export const getFinalSubmittedComponentDetails = async (epid: string) => {
  return fetcher.get<any>(`/monitoring/submitted/${epid}`);
};
export const getDemandNotePdf = async (epid: any) => {
  return fetcher.get<any>(`/projects/land-ownership/${epid}`, {
    download: true,
    origin: "https://admin.moefcc-gcpregistry.in/",
  });
};

export const getFundApplicationPdf = async (epid: any) => {
  return fetcher.get<any>(`/projects/land-ownership/fund-request/${epid}/pdf`);
};

export const putCostEstimateQueryDetails = async (data: any) => {
  return fetcher.put<any>(`/monitoring/target`, data);
};

export const withdrawProjectStatus = async (id: string, data: any) => {
  return fetcher.put<any>(`/projects/${id}/status`, data);
};

export const putDeselectDetails = async (epid: string, data: any) => {
  return fetcher.put<any>(`/projects/land-ownership/${epid}/withdraw`, data);
};
