import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import {
  Button,
  FileUpload,
  Input,
} from "src/components/common/FormComponents";
import Snackbar from "src/components/common/Snackbar";
import { ProjectType, PROJECT_STATUS_ALL, ROLES } from "src/constants/common";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { useAppSelector } from "src/redux/store";
import { getStyles } from "src/styles/theme";
import { SnackbarType } from "src/types/common";
import {
  queryHistory,
  updateProjectStatus,
  withdrawProjectStatus,
} from "src/utils/api/dashboard";
import WaterProjects from "./LandOwnershipDetails";
import defaultStyles, { StylesClasses } from "./styles";
import TreeProject, { ProjectDetailQuery } from "./TreeProject";

type RemarksForm = {
  remarks: "";
  query: "";
  withdrawRemark: "";
  withdrawDoc: "";
};

const ProjectDetailView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryClient = useQueryClient();

  const projectRemarks = location?.state?.projectRemarks || "";

  const [searchParams] = useSearchParams();

  const projectId = searchParams.get("projectId");
  const projectType = searchParams.get("projectType");
  const projectStatus = searchParams.get("projectStatus");
  const occupiedArea = searchParams.get("isOccupied");

  const userDetails = useAppSelector((state: any) => state.root.user);
  const role = userDetails?.data?.role;

  const styles = getStyles<StylesClasses>(defaultStyles);

  const [showSnackbar, setShowSnackbar] = useState<SnackbarType>({
    severity: "error",
    message: "",
  });

  const { mutate } = useMutation(
    "changeStatus",
    (data: any) => updateProjectStatus(projectId, data),
    {
      onSuccess: (res: AxiosResponse) => {
        navigate(path.registeredLands);
      },
      onError: (err: AxiosError) => {
        setShowSnackbar({
          severity: "error",
          message: err?.response?.data?.message || "Something went wrong",
        });
      },
    }
  );

  const { mutate: mutateWithdrawProject } = useMutation(
    "withdraw",
    ({ id, data }: { id: string; data: any }) =>
      withdrawProjectStatus(id, data),
    {
      onSuccess: () => {
        navigate(`${path.registeredLands}?currentTab=WITHDRAWN`);
      },
      onError: () => {},
    }
  );

  const { mutate: mutateQueryData } = useMutation(
    "query",
    ({
      projectId,
      query,
      type,
      underQuery,
      isEditable,
    }: {
      projectId: string;
      query: string;
      type: string;
      underQuery: string;
      isEditable?: boolean;
    }) =>
      queryHistory({
        projectId,
        query,
        type,
        underQuery,
        isEditable,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: [ProjectDetailQuery, projectId],
        });
        setValue("query", "");
      },
      onError: () => {},
    }
  );

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState,
    setValue,
    getValues,
    clearErrors,
    trigger,
  } = useForm<RemarksForm>({
    mode: "onChange",
    defaultValues: {
      remarks: projectRemarks || "",
    },
  });

  const { errors } = formState;

  const handleForm = (data: RemarksForm) => {
    let status = "";
    if (
      projectStatus === PROJECT_STATUS_ALL.VERIFICATION_PENDING &&
      role === ROLES.REVIEWER
    ) {
      status = PROJECT_STATUS_ALL.REVIEWED;
    } else {
      status = PROJECT_STATUS_ALL.APPROVED; // approver
    }
    mutate({ status: status, ...data });
  };

  const handleReject = () => {
    const remarks = watch("remarks");

    if (remarks) {
      let statusReject = "";
      if (
        projectStatus === PROJECT_STATUS_ALL.VERIFICATION_PENDING &&
        role === ROLES.REVIEWER
      ) {
        statusReject = PROJECT_STATUS_ALL.REVIEW_FAILED;
      } else {
        statusReject = PROJECT_STATUS_ALL.REJECTED;
      }

      mutate({ status: statusReject, remarks: remarks });
      return;
    }
    setError("remarks", { message: errorMessage.required });
  };

  const handleWithDrawal = () => {
    const withdrawDoc = getValues("withdrawDoc");
    const fileIds = Array.isArray(withdrawDoc)
      ? withdrawDoc.map((item) => item.file)
      : [];

    mutateWithdrawProject({
      id: projectId,
      data: {
        status: "WITHDRAWN",
        remark: getValues("withdrawRemark"),
        fileIds,
      },
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleForm)}>
      <DashboardWrapper
        title="Activity Details"
        customStyles={{
          container: { position: "relative" },
          children: { paddingBottom: "80px" },
        }}
        showBackBtn
      >
        {projectType === ProjectType.WATER_CONSERVATION && (
          <WaterProjects projectId={projectId} />
        )}

        {(projectType === ProjectType.TREE_PLANTATION ||
          projectType === ProjectType.LAND_LEASED) && (
          <TreeProject projectId={projectId} />
        )}

        {(projectStatus === PROJECT_STATUS_ALL.VERIFICATION_PENDING ||
          projectStatus === PROJECT_STATUS_ALL.UNDER_QUERY) &&
          role !== "Approver-view-only" && (
            <Box sx={{ marginTop: "30px" }}>
              <Input
                name="query"
                label="Query"
                control={control}
                errors={errors}
                multiline
                height="360px"
                customStyles={{
                  input: {
                    height: "auto",
                    fontFamily: "Poppins",
                    width: { sm: "100%", lg: "80%" },
                  },
                }}
              />
            </Box>
          )}

        {projectStatus === PROJECT_STATUS_ALL.VERIFICATION_PENDING &&
          role !== "Approver-view-only" && (
            <Input
              name="remarks"
              label="Remarks"
              control={control}
              errors={errors}
              multiline
              height="360px"
              customStyles={{
                input: {
                  height: "auto",
                  fontFamily: "Poppins",
                  width: { sm: "100%", lg: "80%" },
                },
              }}
            />
          )}

        {(projectStatus === PROJECT_STATUS_ALL.VERIFICATION_PENDING ||
          projectStatus === PROJECT_STATUS_ALL.UNDER_QUERY) &&
          role !== "Approver-view-only" && (
            <Box {...styles("btnWrapper")}>
              <Button
                text="Reject"
                variant="outlined"
                color="error"
                onClick={() => handleReject()}
                customStyles={{
                  root: {
                    fontSize: "18px",
                    color: "error.main",
                    borderColor: "error.main",
                  },
                }}
              />
              <Button
                text="Add to Land Bank"
                type="submit"
                customStyles={{
                  root: {
                    fontSize: "16px",
                    textWrap: "noWrap",
                    textTransform: "unset",
                  },
                }}
              />
            </Box>
          )}
        {(projectStatus === PROJECT_STATUS_ALL.VERIFICATION_PENDING ||
          projectStatus === PROJECT_STATUS_ALL.UNDER_QUERY) &&
          role !== "Approver-view-only" && (
            <>
              <Button
                text="Raise Query"
                onClick={() => {
                  mutateQueryData({
                    projectId: projectId,
                    query: watch("query"),
                    type: "PROJECT",
                    underQuery: "UNDER_QUERY",
                  });
                }}
                disabled={!watch("query")}
                customStyles={{
                  root: {
                    fontSize: "16px",
                    position: "absolute",
                    bottom: "20px",
                  },
                }}
              />
            </>
          )}
        {projectStatus === PROJECT_STATUS_ALL.APPROVED &&
          Number(occupiedArea) === 0 &&
          role !== "Approver-view-only" && (
            <Box sx={{ marginTop: "30px", width: "60%" }}>
              <FileUpload
                name="withdrawDoc"
                label="Upload Withdrawal Document"
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                clearErrors={clearErrors}
                maxLimit={1}
                multiple={true}
                customStyles={{
                  label: {
                    whiteSpace: "wrap",
                  },
                }}
                supportedFormatMessage="Upload Withdrawal Documents"
                trigger={trigger}
                required={true}
                rules={{
                  validate: {
                    isLength: (value) => {
                      return value && value["length"] >= 1;
                    },
                  },
                }}
                dragDropHeading=""
              />
              <Input
                name="withdrawRemark"
                label="Withdrawal Remark"
                placeholder="Please provide the reason for withdrawing the block"
                control={control}
                errors={errors}
                multiline
                height="360px"
                customStyles={{
                  input: {
                    height: "auto",
                    fontFamily: "Poppins",
                    width: { sm: "100%", lg: "80%" },
                  },
                }}
              />
              <Button
                text="Withdraw Block"
                onClick={() => handleWithDrawal()}
                disabled={!watch("withdrawRemark")}
                customStyles={{
                  root: {
                    fontSize: "16px",
                    position: "absolute",
                    bottom: "20px",
                  },
                }}
              />
            </Box>
          )}
      </DashboardWrapper>
      <Snackbar
        open={!!showSnackbar.message}
        message={showSnackbar.message}
        severity={showSnackbar.severity}
        resetSnackbar={() => setShowSnackbar({ ...showSnackbar, message: "" })}
      />
    </Box>
  );
};

export default ProjectDetailView;
